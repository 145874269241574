<template>
    <b-card no-body class="card-revenue-budget">
        <b-row class="mx-0">
            <b-col md="4" sm="12" class="text-center revenue-report-wrapper">
                <div class="d-sm-flex justify-content-between align-items-center mb-3">
                    <h4 class="card-title mb-50 mb-sm-0">
                        {{ $t("fields.income") }}
                    </h4>
                </div>
                <vue-apex-charts v-if="incomedataseries.length>0" :options="incomeChart.chartOptions" :series="incomedataseries" />
                    <span v-else class="h6 text-center"> {{ $t("fields.sorrynoinc") }} </span>
            </b-col>
            <b-col md="4" sm="12" class="text-center revenue-report-wrapper">
                <div class="d-sm-flex justify-content-between align-items-center mb-3">
                    <h4 class="card-title mb-50 mb-sm-0">
                        {{ $t("fields.expense") }}
                    </h4>
                </div>

                <vue-apex-charts v-if="expensedataseries.length>0" :options="expenseChart.chartOptions" :series="expensedataseries" />
                    <span v-else class="h6 text-center"> {{ $t("fields.sorrynoexp") }} </span>
            </b-col>
            <b-col md="4" class="budget-wrapper">
                <h5 class="font-weight-bolder fs-2">
                    {{ $t("fields.currentmonth") }}
                </h5>
                <div class="d-flex justify-content-center">
                    <span>56,800</span>
                </div>
                <vue-apex-charts id="budget-chart" type="line" height="80" :options="budgetChart.options"
                    :series="data.budgetChart ? data.budgetChart.series : []" />
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
                    {{ $t("fields.editbudget") }}
                    <!-- Edit Budget -->
                </b-button>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import Ripple from "vue-ripple-directive";

export default {
    components: {
        VueApexCharts,
        BDropdown,
        BDropdownItem,
        BCard,
        BButton,
        BRow,
        BCol,
    },
    directives: {
        Ripple,
    },
    props: {
        data: {
            type: Object,
            default: () => {},
        },
        incomeDatadash: {
            type: Object,
            default: () => [],
        },
        expenseDatadash: {
            type: Object,
            default: () => [],
        },


    },
    data() {
        return {
            revenue_report: {},

            incomeChart: {
                chartOptions: {
                    chart: {
                        type: "donut",
                        toolbar: {
                            show: false,
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    legend: { show: false },
                    comparedResult: [2, -3, 8],
                    labels: [],
                    stroke: { width: 0 },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        offsetY: 15,
                                    },
                                    value: {
                                        offsetY: -15,
                                        formatter(val) {
                                            return `₹ ${parseInt(val)}`;
                                        },
                                    },
                                },
                            },
                        },
                    },
                    responsive: [
                        {
                            breakpoint: 1325,
                            options: {
                                chart: {
                                    height: 300,
                                },
                            },
                        },
                        {
                            breakpoint: 1200,
                            options: {
                                chart: {
                                    height: 300,
                                },
                            },
                        },
                        {
                            breakpoint: 1045,
                            options: {
                                chart: {
                                    height: 250,
                                },
                            },
                        },
                        {
                            breakpoint: 992,
                            options: {
                                chart: {
                                    height: 250,
                                },
                            },
                        },
                    ],
                },
            },
            expenseChart: {
                chartOptions: {
                    chart: {
                        type: "donut",
                        toolbar: {
                            show: false,
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    legend: { show: false },
                    comparedResult: [2, -3, 8],
                    labels: [],
                    stroke: { width: 0 },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        offsetY: 15,
                                    },
                                    value: {
                                        offsetY: -15,
                                        formatter(val) {
                                            return `₹ ${parseInt(val)}`;
                                        },
                                    },
                                },
                            },
                        },
                    },
                    responsive: [
                        {
                            breakpoint: 1325,
                            options: {
                                chart: {
                                    height: 300,
                                },
                            },
                        },
                        {
                            breakpoint: 1200,
                            options: {
                                chart: {
                                    height: 300,
                                },
                            },
                        },
                        {
                            breakpoint: 1045,
                            options: {
                                chart: {
                                    height: 250,
                                },
                            },
                        },
                        {
                            breakpoint: 992,
                            options: {
                                chart: {
                                    height: 250,
                                },
                            },
                        },
                    ],
                },
            },
            revenueReport: {
                chartOptions: {
                    chart: {
                        stacked: true,
                        type: "bar",
                        toolbar: { show: false },
                    },
                    grid: {
                        padding: {
                            top: -20,
                            bottom: -10,
                        },
                        yaxis: {
                            lines: { show: false },
                        },
                    },
                    xaxis: {
                        categories: [
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "May",
                            "Jun",
                            "Jul",
                            "Aug",
                            "Sep",
                        ],
                        labels: {
                            style: {
                                colors: "#6E6B7B",
                                fontSize: "0.86rem",
                                fontFamily: "Montserrat",
                            },
                        },
                        axisTicks: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                        },
                    },
                    legend: {
                        show: false,
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    colors: [$themeColors.primary, $themeColors.warning],
                    plotOptions: {
                        bar: {
                            columnWidth: "17%",
                            endingShape: "rounded",
                        },
                        distributed: true,
                    },
                    yaxis: {
                        labels: {
                            style: {
                                colors: "#6E6B7B",
                                fontSize: "0.86rem",
                                fontFamily: "Montserrat",
                            },
                        },
                    },
                },
            },
            // budget chart
            budgetChart: {
                options: {
                    chart: {
                        height: 80,
                        toolbar: { show: false },
                        zoom: { enabled: false },
                        type: "line",
                        sparkline: { enabled: true },
                    },
                    stroke: {
                        curve: "smooth",
                        dashArray: [0, 5],
                        width: [2],
                    },
                    colors: [$themeColors.primary, "#dcdae3"],
                    tooltip: {
                        enabled: false,
                    },
                },
            },
            incomedatalabel: [],
            incomedataseries: [],
            expensedatalabel: [],
            expensedataseries: [],
        };
    },
    mounted() {
        this.$watch(
            "$props",
            () => {
                this.incomeDatadash.forEach(element => {
                    this.incomeChart.chartOptions.labels.push(element.key)
                    this.incomedataseries.push(element.amount)
                });
                this.expenseDatadash.forEach(element => {
                    this.expenseChart.chartOptions.labels.push(element.key)
                    this.expensedataseries.push(element.amount)
                });
            },
            { deep: true }
        );
    }
};
</script>
